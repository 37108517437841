<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Search Roles</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="addrole">Add New Role</b-button>
            </b-col>
          </b-row>
          <b-row>
            
            <b-col cols="4">
              <label>Search</label>
              <b-form-input v-model="searchTerm" @keyup="search"></b-form-input>
            </b-col>
          </b-row>     
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="12">
             
              <b-table striped hover
                       sort-icon-left
                       :items="tableData.dataSource" 
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" 
                       @row-clicked="openRole"
                       :per-page="tableData.resultsPerPage"
                       id="SearchRole"
                       :current-page="tableData.currentPage">

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>
                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="openRole(row.item)" size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
              <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="SearchRole"
                ></b-pagination>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>
<style >
.primary{
background-color: #ffcb14;
color: #222222;
}
.primary:hover{
    color: #fff;
}
.page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
}
</style>
<script>

import {mapActions, mapMutations} from "vuex";
import api from "../../../api";


export default {
  name: "SearchRole",
  data: () => ({
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Role',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Tags',
          key: 'displayTags',
          sortable: true,
          tdClass: '',
        },
       {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      
      ],
      
    },
   
    roles: [],
    searchTerm: "",
  }),
  created() {
    
    this.setBreadcrumb([
      {
        text: 'Roles'
      },
    ]);
   const s = (data) => {
      this.tableData.dataSource = data;
      this.roles = data;
      console.log( data);
       this.tableData.isLoading = false;
   };

   const e = (msg) => {
     console.log(msg);
   };

    api.getRoles(s, e);
  },
  methods: {
    ...mapActions(['searchrolesRequest', "getroleRoles"]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage', 'addSuccessMessage']),

    search() {
      this.tableData.dataSource = this.roles.filter((x) => 
      x.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || x.displayTags.toLowerCase().includes(this.searchTerm.toLowerCase()) 
      );  
    },

    openRole(roles) {
 
      localStorage.setItem("view_role_id", roles.id);
      this.$router.push({path: '/admin/ManageRoles/RoleView'})
    },
    
    addrole() {
      this.$router.push({path: '/admin/ManageRoles/CreateRole'})
    },

   
  },
  computed: {
      rows() {
          return this.tableData.dataSource.length
      },
  },
}
</script>

<style scoped>

</style>